// 2 agosto 2024 funciones para compartir la app

import { Component, OnInit } from "@angular/core";
import { MenuController, ModalController, NavController, Platform } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { Router } from "@angular/router";
import { OneSignal } from "@ionic-native/onesignal/ngx";
import { environment } from "src/environments/environment";
import { TranslateService } from "@ngx-translate/core";
import { PushNotificationService } from "./services/push-notification.service";
import Swal from "sweetalert2";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { ApisService } from "./services/apis.service";
import { AngularFireMessaging } from "@angular/fire/compat/messaging";
import { UtilService } from "./services/util.service";

@Component({
	selector: "app-root",
	templateUrl: "app.component.html",
	styleUrls: ["app.component.scss"],
})
export class AppComponent {
	mesaggeReceived: any;

	notiSound = new Audio();
	loggedIn = false;
	dark = false;

	appPages = [{
			title: "Inicio",
			url: "/main/home",
			icon: "home",
		},
		{
			title: "Taquizas",
			url: "/main/home",
			icon: "restaurant",
		},
		{
			title: "Soporte",
			url: "/main/home",
			icon: "headset",
		},
		{
			title: "Aviso de Privacidad",
			url: "/main/home",
			icon: "information-circle",
		},
	];
	username: string = "USER";
	name: any = "";
	photo: any = "";

	constructor(
		private platform: Platform,
		private splashScreen: SplashScreen,
		private statusBar: StatusBar,
		private router: Router,
		private translate: TranslateService,
		private pushServ: PushNotificationService,
		private fireAuth: AngularFireAuth,
		private api: ApisService,
		private util: UtilService,
		private menuCtrl: MenuController,
		private modalCtrl: ModalController,
		private navCtrl: NavController
	) {
		translate.setDefaultLang("es");
		translate.use("es");
		this.initializeApp();
		this.notiSound.src = "/assets/audios/noti.mp3";
		this.notiSound.load();
	}

	initializeApp() {
		this.platform.ready().then(() => {
			this.platform.backButton.subscribeWithPriority(999, async () => {
				if (this.modalCtrl.getTop()) {
					const modal = await this.modalCtrl.getTop();
					console.log(modal)
					if (modal) {
						this.modalCtrl.dismiss();
						return;
					} else {
						if (this.router.url == "/tabs") {
							navigator['app'].exitApp();
						} else {
							this.navCtrl.pop();
						}
					}
				} else {
					if (this.router.url == "/tabs") {
						navigator['app'].exitApp();
					} else {
						this.navCtrl.pop();
					}
				}
			});
		});
		this.ionViewDidEnter();

		this.fireAuth.authState.subscribe((u) => {
			console.log("escuchamos el cambio de usuario")
			if (u) {
				// this.username = u.displayName || "User";
				this.loggedIn = true;
				const fullname = localStorage.getItem("UserName");
				if (!u.displayName) {
					u.updateProfile({
						displayName: fullname,
						photoURL: "",
					})

				}
				this.getProfile();

			} else {
				this.loggedIn = false;
			}
		});

	}

	ionViewDidEnter() {

		this.pushServ.requestPermission().then((token: any) => {
			localStorage.setItem("fcm", token);
			this.pushServ.updatefcmToken(token);
		});

		// this.util.subscribeLoggedIn().subscribe(c=>{
		//   if(c==true){
		//     this.loggedIn = true;
		//   }
		// })

		this.pushServ.receiveMessage().subscribe((payload) => {
			console.log(payload);

			var notiSound = new Audio();
			notiSound.src = "/assets/audios/noti.mp3";
			notiSound.play();

			Swal.fire({
				position: "top-end",
				icon: "info",
				title: payload.notification.title,
				text: payload.notification.body,
				showConfirmButton: false,
				timer: 10000,
				toast: true,
			});
		});

	}

	openTutorial() {
		// this.menu.enable(false);
		// this.storage.set('ion_did_tutorial', false);
		// this.router.navigateByUrl('/tutorial');
	}

	logout() {
		this.api.logout();
	}
	closeMenu() {
		this.menuCtrl.close();
	}

	getProfile() {
		if (localStorage.getItem('uid')) {

			this.api.getMyProfile(localStorage.getItem('uid')).then((data: any) => {
				//console.log(userdata', data);
				if (data) {
					this.name = data.fullname;
					this.photo = data && data.cover ? data.cover : 'assets/imgs/user.jpg';
				}
			}).catch(error => {
				console.log(error);
			});
		}
	}

	openWhatsApp(): void {
		const phoneNumber = '+525560712115'; // Número de teléfono
		const message = encodeURIComponent('Quiero más información'); // Mensaje codificado

		window.open(`https://api.whatsapp.com/send?phone=+521${phoneNumber}&text=${message}`, '_blank');
	}

	Taquiza() {
		window.open('https://api.whatsapp.com/send?phone=+525560712115&text=Quiero%20mas%20información%20', '_blank')
	}
	// funciones para compartir la app
	copyToClipboard() {
		const url = 'https://webapp.losguerostaqueria.com/'; // La URL de tu app
		navigator.clipboard.writeText(url).then(() => {
			alert('Dirección copiada al portapapeles');
		}, (err) => {
			console.error('Error al copiar al portapapeles: ', err);
		});
	}

	get facebookShareUrl(): string {
		const url = 'https://webapp.losguerostaqueria.com/'; // La URL de tu app
		return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
	}

	get mailtoLink(): string {
		const url = 'https://webapp.losguerostaqueria.com/'; // La URL de tu app
		const subject = 'Te recomiendo esta taqueria';
		const body = `¡Hola!\n\nQuiero recomendarte esta taqueria ${url}\n\nSaludos.`;
		return `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
	}
}